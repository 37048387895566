/* Table Styles */
.table {
    width: 100%; /* Ensures the table stretches to the width of its container */
    border-collapse: collapse; /* Removes space between borders */
    margin-top: 20px; /* Adds space above the table */
    background-color: #f7f7f7; /* Light background to keep the table subtle and professional */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners for the table */
    overflow: hidden; /* Ensures the border radius clips the content */
    table-layout: fixed; /* Forces the table to ignore content size and respect the given column widths */
  }
  
  /* Column Widths */
  .table thead th,
  .table tbody td {
    width: 20%; /* Divides the table into equal parts for each column */
  }
  
  /* Table Header Styles */
  .table thead th {
    background-color: #209385; /* Matching the button base color */
    color: white; /* White text for contrast */
    padding: 12px 15px; /* Comfortable padding */
    text-align: center; /* Centers header text horizontally */
    font-size: 18px; /* Slightly larger font size for headers */
  }
  
/* Table Body Styles */
.table tbody td {
    padding: 10px 15px; /* Consistent padding with the headers */
    border-top: 2px solid #d2e9e6; /* Light border for subtle separation */
    color: #333; /* Darker text for readability */
    background-color: white; /* White background for rows to maintain clean look */
    text-align: center; /* Centers text to maintain consistency with button alignments */
  }
  
  .table tbody td:first-child {
    background-color: #209385; /* Matching the button base color */
    color: white; /* White text for contrast */
    font-size: 18px; /* Slightly larger font size to match headers */
  }
  
  /* Ensure first column text alignment and styling matches header */
  .table tbody tr:hover td:first-child {
    background-color: #1a7a6c; /* Slightly darker green on hover for contrast */
  }
  