/* pdfBuilder.css */

.pdf-builder {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.input-group {
    margin: 10px 0;
    width: 100%;
    max-width: 800px;
}
  
.input-group label {
    display: block;
    margin-bottom: 5px;
}
  
.input-group input[type="text"],
.input-group input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
}