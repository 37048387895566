/* cites styles */

.cites {
    /* Estils per al div general */
    text-align: center; 
    font-size: 14px;
    color: #333;
}

.cites a {
    /* Estils per als enllaços dins del div */
    color: #209385;
    text-decoration: underline;
}

.cites br {
    /* Estils per als salts de línia dins del div */
    margin-bottom: 10px; /* Exemple d'estil */
}
