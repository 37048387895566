/* Selectors element styles */

select {
  padding: 8px 15px;
  border-radius: 4px;
  border: 2px solid #209385;
  background-color: white;
  font-size: 16px;
  margin: 10px 0;
}

select option {
  padding: 8px;
}

.centered-select {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Opcional, per donar espai a dalt */
}