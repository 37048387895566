/* Footer styles */
footer {
    background-color: #d2e9e6;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    margin: auto;
    width: 80%;
    font-size: 16px;
  }
  
  .app-footer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    font-size: 14px;
  }
  
  .footer-column {
    width: 22%; /* Canviat de 30% a 22% per a 4 columnes */
    margin-bottom: 20px;
    text-align: left;
}
  
  .footer-column a {
    color: #19756a;
    text-decoration: none;
  }
  
  .footer-column a:hover {
    text-decoration: underline;
  }
  
  .social-media-links a {
    margin-right: 15px;
    font-size: 1em;
  }
  
  @media (max-width: 768px) {
    .footer-column {
        width: 100%;
        text-align: center;
    }
  }

  p.footer {
    margin-top: 10px; 
    margin-bottom: 0px;
  }

  .contact-button {
    background: none;
    border: none;
    color: #19756a;
    text-decoration: none; /* Elimina el subratllat per defecte */
    cursor: pointer;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

.contact-button:hover {
    text-decoration: underline; /* Afegeix el subratllat només quan s'hi passa el cursor */
}