/* Base Button Styles */

.button-container {
  display: flex;
  flex-direction: column; /* Alinea els fills verticalment */
  align-items: center;     /* Centra els fills horitzontalment */
  justify-content: center; /* Afegeix això si vols centrar-los també verticalment */
  gap: 10px;               /* Espai entre els botons */
  width: auto;             /* Assegura que el contenidor no ocupa tota l'amplada possible */
}

/* Reset Button Styles */
.button, .button-left, .button-center, .button-right, .button-selectable {
  all: unset; /* Remove all inherited and browser-default styles */
  display: inline-block; /* Consistent display type for all buttons */
  padding: 9px 15px; /* Your custom padding */
  border-radius: 4px; /* Your custom border radius */
  border: 2px solid #209385; /* Your custom border */
  background-color: white; /* Your custom background color */
  font-size: 16px; /* Your custom font size */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center the text inside the button */
  white-space: nowrap; /* Prevent the text inside from wrapping */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-right: auto; /* Default right margin auto for alignment */
  color: #000000; /* Custom text color for selected state */
  width: auto;
}

.button:hover {
  background-color: #209385; /* Custom hover state */
  color: #ffffff; /* Custom text color for selected state */
}

.button.selected {
  background-color: #209385; /* Custom selected state */
  color: white; /* Custom text color for selected state */
}

.button-selectable {
  margin: auto; /* Center the button by setting auto margins on all sides */
  background-color: #ffffff; /* Custom selected state */
  color: rgb(0, 0, 0); /* Custom text color for selected state */
  border: 2px solid #79beb5; /* Your custom border */
}

.button-selectable:hover {
  background-color: #a5d3ce; /* Custom selected state */
}

.button-selectable.selected {
  background-color: #a5d3ce; /* Custom selected state */
}

.button-selectable.disabled {
  background-color: #e0e0e0; /* Fons gris clar */
  color: #a0a0a0; /* Text gris apagat */
  border-color: #d0d0d0; /* Vora gris */
  cursor: not-allowed; /* Cursor d’invalidació */
}

.button-center {
  margin: 5px; /* Add margin to all sides */
  width: auto;
}

.button-center:hover {
  background-color: #209385; /* Custom hover state */
  color: #ffffff; /* Custom text color for selected state */
}

.button-right {
  float: right; /* Push the button to the right */
  margin-right: 0; /* No right margin for .button-right */
  width: auto;
}

.button-left {
  float: left; /* Align the button to the left */
  margin-left: 0; /* No left margin for .button-left */
  width: auto;
}

.button-link {
  display: inline-block; /* Inline-block display for links styled as buttons */
  background-color: white; /* Darker background on hover */
  border: 2px solid #209385; /* Your custom border */
  color: rgb(0, 0, 0); /* Custom text color */
  text-decoration: none; /* No underline */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 15px; /* Padding around text */
  font-size: 16px; /* Adjust font size */
  width: auto;
}

.button-link:hover {
  border-radius: 5px; /* Your custom border radius */
  border: 2px solid #209385; /* Your custom border */
  background-color: #209385; /* Custom background color */
  color: rgb(255, 255, 255); /* Custom text color */
}

.workshop-button {
  font-size: 18px; /* Ensure consistent font size */
  padding: 10px 20px; /* Ensure consistent padding */
}

.navigation-button-container {
  display: flex;
  justify-content: center; /* Centra els botons horitzontalment */
  align-items: center;     /* Alinea els botons verticalment al centre */
  flex-wrap: wrap;         /* Permet que els botons es moguin a la línia següent si no caben */
  gap: 10px;               /* Espai entre els botons */
  padding: 10px;           /* Padding dins del contenidor per donar més espai al voltant dels botons */
  margin-top: 20px;        /* Margin superior */
  margin-bottom: 20px;     /* Margin inferior */
  width: auto;
}

.navigation-button {
  background-color: #209385; /* Color de fons del botó */
  border: 2px solid #209385; /* Borda del botó */
  color: rgb(255, 255, 255); /* Color del text dins del botó */
  padding: 8px 16px; /* Padding dins del botó */
  text-align: center; /* Text centrat dins del botó */
  cursor: pointer; /* Mostra un cursor de punter quan es passa sobre el botó */
  font-size: 16px; /* Mida de la lletra dins del botó */
  border-radius: 4px; /* Racons arrodonits del botó */
  margin: 5px; /* Espaiat petit al voltant de cada botó */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Ombra lleu per donar profunditat */
  width: auto;
}

.navigation-button:hover {
  background-color: #ffffff; /* Fons més fosc en passar el cursor */
  color: #000000; /* Custom text color for selected state */
}

.button.incorrect {
  background-color: #ffcccc; /* Light red background for incorrect answers */
  border-color: #ff0000; /* Red border for emphasis */
  color: #000000; /* Ensure text is readable */
}

.button.correct {
  background-color: #ccffcc; /* Light green background for correct answers */
  border-color: #008000; /* Green border for emphasis */
  color: #000000; /* Ensure text is readable */
}

/* Play Button Styles */

.play-button {
  all: unset; /* Remove all inherited and browser-default styles */
  display: inline-block; /* Consistent display type for all buttons */
  padding: 10px 20px; /* Your custom padding */
  border-radius: 4px; /* Your custom border radius */
  border: 2px solid #209385; /* Base color border */
  background-color: #d2e9e6; /* Lightest background color */
  font-size: 16px; /* Your custom font size */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center the text inside the button */
  white-space: nowrap; /* Prevent the text inside from wrapping */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-right: auto; /* Default right margin auto for alignment */
  color: #209385; /* Base color text */
}

.play-button:hover {
  background-color: #79beb5; /* Lighter background color on hover */
  border-color: #79beb5; /* Lighter border color on hover */
  color: #fff; /* White text on hover */
}

.audio-uploader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* New Button Styles */

.link-container {
  display: flex;
  align-items: center;
}

.new-indicator {
  color: red;
  font-weight: bold;
  margin-left: 10px; /* Space between the button and the indicator */
}

.highlighted-section {
  display: block; /* Canviem a block per gestionar millor l'alineació */
  border: 2px dotted #79beb5;
  padding: 20px;
  margin: 20px auto; /* Això assegura que la secció estigui centrada */
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  width: 80%; /* Ajusta l'ample segons les teves necessitats */
  max-width: 600px; /* Limita l'amplada màxima per evitar que sigui massa ample */
  box-sizing: border-box; /* Assegura que el padding no afecti l'amplada */
}

.icon-forward::before {
  content: '➤'; /* Unicode per a una icona de reenviar */
  font-size: 20px;
}

/* Input Styles */

.input-field {
  all: unset; /* Remove all inherited and browser-default styles */
  display: inline-block; /* Consistent display type */
  padding: 8px 15px; /* Custom padding */
  border-radius: 4px; /* Custom border radius */
  border: 2px solid #209385; /* Custom border */
  background-color: white; /* Custom background color */
  font-size: 16px; /* Custom font size */
  cursor: text; /* Text cursor */
  text-align: center; /* Center the text inside the input */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  color: #000000; /* Custom text color */
  width: auto; /* Auto width */
  min-width: 100px; /* Minimum width for the input */
}

.input-field:focus {
  border-color: #209385; /* Border color on focus */
  outline: none; /* Remove default outline */
}

/* Estils per als enllaços restringits */
.restricted-link {
  position: relative;
  display: inline-block;
}

.restricted-marker {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 4px;
  font-size: 14px;
  color: #ff0000; /* Color del candau */
  font-weight: bold;
}

/* Adaptació per a pantalles estretes */
@media (max-width: 600px) {
  .icon-container {
    width: 100%;              /* Ocupa tota l'amplada disponible en pantalles estretes */
  }
  .link-icon {
    width: 150px;             /* Redueix la mida de les icones per a pantalles estretes */
    height: 150px;            /* Manté la proporció de l'alçada i l'amplada */
  }
}

/* Adaptació per a pantalles amples */
@media (min-width: 601px) {
  .icon-container {
    flex: 1;                  /* Cada icona ocupa un espai igual en el contenidor */
  }
}


.delete-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.button-important {
  display: inline-block; 
  background-color: #ffffff; 
  border: 2px solid #ff1a1a; 
  color: #ff1a1a; 
  text-decoration: none; 
  font-size: 16px;
  border-radius: 5px; 
  padding: 9px 15px;; 
}

.button-important:hover {
  background-color: #ff1a1a;
  color: #ffffff;
}

.button-important-small {
  display: inline-block; 
  background-color: #ffffff; 
  border: 1px solid #ff1a1a; 
  color: #ff1a1a; 
  text-decoration: none; 
  font-size: 14px;
  border-radius: 5px; 
  padding: 3px 6px;; 
}

.button-important-small:hover {
  background-color: #ff1a1a;
  color: #ffffff;
}

/* Estil per als botons que semblen enllaços senzills */
.button-simplelink {
  background: none; /* Elimina el fons */
  border: none; /* Elimina la vora */
  color: #19756a; /* Color de l'enllaç */
  cursor: pointer; /* Canvia el cursor a pointer */
  text-decoration: none; /* Sense subratllat per defecte */
  font-size: 16px; /* Mida de la lletra */
  padding: 0; /* Elimina el padding per defecte */
}

.button-simplelink:hover {
  text-decoration: underline; /* Subratlla quan es passa per sobre */
}