/* Contenidor general */
.concept-manager-container {
    display: flex;
    flex-direction: column; /* Alinea tot en vertical */
    gap: 20px; /* Espai entre els contenidors */
    padding: 20px;
    max-width: 800px; /* Amplada màxima del contenidor */
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Contenidor de cada panell */
  .concept-panel,
  .editable-list-container,
  .tree-view-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  /* Estils específics per als conceptes disponibles */
  .concept-panel {
    max-height: 300px; /* Límita l'alçada màxima */
    overflow-y: auto; /* Desplaçament vertical si hi ha molts conceptes */
  }
  
  /* Contenidor de la llista editable */
.editable-list-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f4f4f4;
    overflow-y: auto;
    max-height: 400px; /* Límita l'alçada màxima */
  }
  
  /* Contenidor dels elements de la llista */
  .list-container {
    margin-top: 10px;
  }
  
  /* Cada element de la llista */
  .editable-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 8px;
  }
  
  /* Etiqueta de cada element */
  .list-item-label {
    flex: 1; /* Ocupa l'espai restant */
  }
  
  /* Contenidor dels botons */
  .editable-button-container {
    display: flex;
    gap: 8px;
  }
  
  /* Botons */
  .editable-button {
    all: unset;
    display: inline-block;
    padding: 5px 10px;
    border: 2px solid #209385;
    background-color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
  }
  
  .editable-button:hover {
    background-color: #209385;
    color: white;
  }
  
  /* Botó important */
  .editable-button-important {
    background-color: #ffcccc;
    border: 2px solid #ff0000;
    color: #721c24;
  }
  
  .editable-button-important:hover {
    background-color: #ff0000;
    color: white;
  }

/* Botó "+" de color verd */
.editable-button-plus {
    all: unset;
    display: inline-block;
    font-size: 24px; /* Augmentem la mida del símbol */
    font-weight: bold;
    color: #209385; /* Verd */
    cursor: pointer;
    padding: 2px; /* Espai al voltant del símbol */
  }
  
  .editable-button-plus:hover {
    color: #1b6f60; /* Verd més fosc en passar el cursor */
  }
  
  /* Botó "-" de color vermell */
  .editable-button-minus {
    all: unset;
    display: inline-block;
    font-size: 24px; /* Augmentem la mida del símbol */
    font-weight: bold;
    color: #ff0000; /* Vermell */
    cursor: pointer;
    padding: 0px
    5px; /* Espai al voltant del símbol */
  }
  
  .editable-button-minus:hover {
    color: #b30000; /* Vermell més fosc en passar el cursor */
  }
  