/* Utilitats generals */

.full-width {
  width: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end-right {
  display: flex;
  justify-content: flex-end;
}

/* Estils de layout específics */
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  width: 100%; 
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-right: 5%;
  padding-left: 5%;
  width: 100%; 
}

.main-content p {
  text-align: left;
  width: 100%;
}

.auth-container {
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  width: 100%; 
  margin-right: 20px;
  margin-top: 20px;
}

.content-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
}

.app-container {
  width: 100%; 
}

.app-footer {
  font-size: 14px;
  color: rgb(44, 44, 44);
  text-align: center;
  width: 100%;
}