/* ===== Formulari CAPE-Vr ===== */
.capevr-form {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}

.capevr-section {
    margin-bottom: 20px;
}

.capevr-section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #209385;
}

.audio-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horitzontalment */
  justify-content: center; /* Centra verticalment */
  text-align: center;
  margin: 20px 0;
}

/* ===== Demographics ===== */

/* Contenidor principal */
.demographics {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    background-color: white;
    box-sizing: border-box;
  }
  
  /* Títol */
  .demographics h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #209385;
  }
  
  /* Grid layout per als elements */
  .demographics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Ajustable segons l'ample */
    gap: 15px; /* Espai entre elements */
    align-items: center; /* Centra verticalment */
  }
  
  /* Cada element de demografia */
  .demographics-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  /* Camps d'entrada */
  .demographics-input {
    width: 100%;
    padding: 6px 8px; /* Reduït l'espai interior */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px; /* Mida de lletra més petita */
    box-sizing: border-box;
    background-color: white;
  }
  
  /* Estil del focus */
  .demographics-input:focus {
    border-color: #209385;
    outline: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .demographics-grid {
      grid-template-columns: repeat(2, 1fr); /* Dues columnes en pantalles mitjanes */
    }
  }
  
  @media (max-width: 480px) {
    .demographics-grid {
      grid-template-columns: 1fr; /* Una sola columna en pantalles petites */
    }
  }  

/* ===== Tasks Section ===== */

.capevr-sentences-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  gap: 1rem; /* Espai entre columnes */
  align-items: center; /* Alineació vertical */
}

.capevr-sentences-column {
  list-style-type: lower-alpha;
  padding-left: 20px;
}

.capevr-checkbox-column {
  display: flex;
  justify-content: center; /* Centra la checkbox */
  align-items: center;
}

.capevr-checkbox-label {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
  
/* ===== Conditions Sections (Compact Grid) ===== */
.capevr-conditions-section {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid #209385;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: grid; /* Utilitzem un layout en forma de grid */
    grid-template-columns: repeat(3, 1fr); /* Tres columnes d'amplada igual */
    grid-gap: 15px; /* Espai entre els elements */
  }
  
  /* Inputs i selects dins del grid */
  .capevr-conditions-section .input-field {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #209385;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: white;
  }
  
  .capevr-conditions-section .input-field:focus {
    border-color: #4cb0a5;
    outline: none;
  }  

/* ===== Rating Sliders ===== */
.capevr-rating {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espai entre sliders */
}

.capevr-rating-row {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px; /* Espai entre el desplegable/etiqueta i el slider */
  margin-bottom: 15px; /* Espai entre cada fila */
}

.capevr-rating-label, 
.capevr-select-field { /* Igualem l'amplada per a etiquetes i desplegables */
  flex: 0 0 120px; /* Amplada fixa */
  text-align: right;
  font-weight: bold;
  color: #333;
}

.capevr-slider {
  flex: 1;
  appearance: none;
  height: 5px; /* Alçada del track del slider */
  background-color: #ddd;
  border-radius: 4px;
  outline: none;
  padding-right: 35px; /* Això és el que fa que les sliders no arribin al final i l'etiqueta "extreme" pugui quedar al mig de l'extrem dret de la slider */
}

.capevr-slider::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  background: #209385;
  border-radius: 50%;
  cursor: pointer;
}

.capevr-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #209385;
  border-radius: 50%;
  cursor: pointer;
}

/* Contenidor per a les etiquetes de la escala */
.capevr-scale-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; /* Espai entre les etiquetes i els sliders */
}

/* Etiquetes de la escala */
.capevr-scale-label {
  position: absolute;
  top: -10px; /* Alinea millor sobre la barra */
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
}

/* Etiqueta de l'esquerra ("Normal") */
.capevr-scale-left {
  left: 120px;  /* Ajusta segons l'ample de les etiquetes dels atributs */
}

/* Etiqueta de la dreta ("Extreme") */
.capevr-scale-right {
  right: 0;
  text-align: right;
}

/* Ajustem l'amplada del desplegable perquè coincideixi amb les etiquetes */
.capevr-select-field {
  width: 120px; /* Amplada fixa igual que les etiquetes */
  padding: 5px; /* Reduïm l'espai interior per ajustar l'alçada */
  border: 1px solid #209385;
  border-radius: 4px;
  font-size: 14px; /* Lletres lleugerament més petites */
  background-color: white;
  box-sizing: border-box;
  text-align: center; /* Centrem el text dins del desplegable */
}

.capevr-info-message {
  color: #555;
  font-size: 0.9rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
}

/* ===== Radio Buttons i Textarea ===== */
.capevr-radio-group {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    margin-bottom: 15px;
}

.capevr-radio-label {
    flex: 0 0 120px; /* Amplada fixa per a les etiquetes */
    text-align: right;
    font-weight: bold;
    color: #333;
}

.capevr-radio-buttons-and-textarea {
    flex: 1; /* Ocupa tot l'espai restant */
    display: flex;
    flex-direction: column; /* Col·loca els radio buttons i el textarea en columna */
    gap: 10px; /* Espai entre els radio buttons i el textarea */
}

.capevr-radio-buttons {
    display: flex;
    gap: 10px; /* Espai entre cada radio button */
    flex-wrap: wrap; /* Permet que els radio buttons baixin si no hi ha espai */
}

.capevr-radio-buttons label {
    display: flex;
    align-items: center;
    gap: 5px; /* Espai entre el cercle del radio button i el text */
}

.capevr-textarea {
    all: unset;               /* Elimina estils predeterminats del navegador */
    width: 100%;              /* Amplada completa dins de la columna */
    padding: 5px 10px;        /* Espai interior */
    border-radius: 4px;       /* Contorn arrodonit */
    border: 1px solid #209385; /* Contorn */
    background-color: white;  /* Fons blanc */
    font-size: 16px;          /* Mida del text */
    box-sizing: border-box;   /* Inclou padding i contorn a la mida total */
    min-height: 30px;         /* Alçada mínima */
    resize: vertical;         /* Permet redimensionar verticalment */
}

/* ===== Instabilities Evaluation ===== */
/* Contenidor per als checkboxes i textarea */
/* Contenidor de les checkboxes en horitzontal */
  .capevr-checkboxes-horizontal {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Separació entre els elements */
  }

  /* Etiquetes per cada checkbox */
  .capevr-checkbox-label-inline {
    display: flex;
    align-items: center;
    gap: 8px; /* Separació entre el checkbox i el text */
  }

  /* Input per a l'etiqueta "Other" */
  .capevr-input-inline {
    margin-left: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Textarea */
  .capevr-textarea {
    all: unset;               /* Elimina estils predeterminats del navegador */
    width: 100%;              /* Amplada completa dins del contenidor */
    padding: 5px 10px;        /* Espai interior */
    border-radius: 4px;       /* Contorn arrodonit */
    border: 1px solid #209385; /* Contorn */
    background-color: white;  /* Fons blanc */
    font-size: 16px;          /* Mida del text */
    box-sizing: border-box;   /* Inclou padding i contorn a la mida total */
    min-height: 30px;         /* Alçada mínima */
    resize: vertical;         /* Permet redimensionar verticalment */
  }  

  .capevr-textarea-wrapper {
    flex: 1; /* Ocupa tota la columna de la dreta */
  }

/* ===== Submit Button ===== */
.capevr-submit-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #209385;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.capevr-submit-button:hover {
    background-color: #4cb0a5;
}