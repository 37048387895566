/* header styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    gap: 20px;
    background-image: url('/public/images/Portada.png');
    background-size: cover;
    background-position: center;
    height: 300px;
    color: white;
    position: relative;
    z-index: 10;
}

.right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.link-style {
    text-decoration: none;
    color: inherit;
}

/* Estils per al títol dins del header */
.header h1 {
    background-color: rgba(255, 255, 255);
    padding: 5px;
    border-radius: 5px;
    margin: 0;
    color: #209385;
}

.header .right-section .auth-links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.header .right-section .auth-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.header .right-section .header-link {
    cursor: pointer;
    color: #000;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s;
    font-size: 16px;
}

.header .right-section .header-link:hover {
    background-color: #a5d3ce;
    text-decoration: underline;
}

.translation-link-container {
    background: white;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    text-align: right;
    margin-top: -8px;
}

.translation-link {
    font-size: 12px;
    margin-top: 8px;
    margin: 0;
    color: #000;
    cursor: pointer;
    text-decoration: none;
}

.translation-link:hover {
    text-decoration: underline;
}
