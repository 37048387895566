/* 🔹 Contenidor de cada icona i text */
.icon-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

/* 🔹 Estil per als botons d'icona */
.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

/* 🔹 Estil per a les icones */
.link-icon {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: opacity 0.3s;
}

.icon-button:hover .link-icon {
  opacity: 0.8;
}

/* 🔹 Estil per al text de les icones */
.link-text {
  font-size: 18px;
  text-align: center;
  color: #19756a;
  margin-top: 5px;
}

.link-text:hover {
  text-decoration: underline;
}

/* 🔹 Contenidor dels botons d'icona */
.icon-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

/* 🔹 Estil per a les mini-icones */
.mini-icon {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  transition: opacity 0.3s;
}

/* 🔹 Tooltip */
.link-tooltip {
  visibility: hidden;
  background-color: #209385;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  width: 150px;
}

/* 🔹 Mostrar Tooltip quan es passa per sobre de la icona */
.icon-container:hover .link-tooltip {
  visibility: visible;
  opacity: 1;
}

/* 🔹 Contenidor de navegació */
.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.resource-category {
  width: 100%; /* Garanteix que cada categoria ocupa tota l'amplada disponible */
  max-width: 500px; /* Opcional: limitar l'amplada per a millor llegibilitat */
  text-align: center;
}

.category-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
}

.resource-list {
  display: flex;
  flex-wrap: wrap; /* Permet que els recursos s’ajustin en files si cal */
  justify-content: center; /* Centra els elements dins del bloc */
  gap: 15px; /* Espai entre elements */
}