/*VocalAttributeRatingInput*/ 

.VocalAttributeRatingInput {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .VocalAttributeRatingInput-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .VocalAttributeRatingInput-col {
    margin: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .VocalAttributeRatingInput-col-left {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    width: 20%;
    color: rgb(0, 0, 0);
  }
  
  .VocalAttributeRatingInput-col-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    color: white;
  }
  
  .VocalAttributeRatingInput-col-right {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 20%;
  }
  
  .VocalAttributeRatingInput-slider {
    width: 100%
  }
  
  .VocalAttributeRatingInput-radio {
    display: flex;
    flex-direction: row;
  }

  .direction-deviation-container {
    display: block; /* Makes sure the container starts on a new line */
    text-align: center; /* Optionally center the checkboxes */
    margin-top: 10px; /* Adds some space above the checkboxes */
}

/* Additional styling for the DirectionDeviation component, if needed */
.direction-deviation-container label {
    margin-right: 10px; /* Adds space between the checkboxes */
}

.VocalAttributeRatingInput-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.VocalAttributeRatingInput-radio label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.VocalAttributeRatingInput-radio label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #209385;
  border-radius: 50%;
  background-color: white;
}

.VocalAttributeRatingInput-radio input[type="radio"]:checked + label::before {
  background-color: #4cb0a5; /* Use your theme color */
}

.VocalAttributeRatingInput-checkbox label {
  position: relative;
  padding-left: 25px; /* Enough space for the custom checkbox */
  cursor: pointer;
  display: inline-block;
  line-height: 20px; /* Adjust to align vertically with the text */
}

.VocalAttributeRatingInput-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  width: 16px;  /* Set to the size of the pseudo-element */
  height: 16px; /* Set to the size of the pseudo-element */
}

.VocalAttributeRatingInput-checkbox label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #209385;
  background-color: white;
  border-radius: 3px; /* Adjust for rounded corners if needed */
}

.VocalAttributeRatingInput-checkbox input[type="checkbox"]:checked + label::before {
  background-color: #4cb0a5;
  content: '✓';
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 16px; /* Ensure the checkmark is vertically centered */
}
