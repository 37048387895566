.data-visualization-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .controls-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    gap: 20px;
  }
  
  .data-flow-section, .visualization-toggles-section {
    flex: 1;
    flex-basis: 50%;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .checkbox-container div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .checkbox-container label {
    font-size: 16px;
  }
  
  .data-flow-section h3, .visualization-toggles-section h3 {
    margin-bottom: 20px;
    color: #209385;
  }
  