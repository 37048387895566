.grid-container {
  display: grid;
  grid-template-columns: minmax(150px, auto) repeat(auto-fit, minmax(100px, 1fr)); /* First column auto-sized, other columns auto-fit with a minimum width */
  gap: 10px;
}
  
.grid-item {
  padding: 10px; /* Padding around grid items */
  border-radius: 4px; /* Border radius */
  border: 2px solid #209385; /* Border color */
  background-color: #d2e9e6; /* Lightest background color */
}
  
.grid-item:hover {
  background-color: #79beb5; /* Lighter background color on hover */
}
  