/* Form */
.register-form {
  display: flex;
  flex-direction: column; /* Alinea els fills verticalment */
  gap: 10px;               /* Espai entre els elements del formulari */
  width: 100%;             /* Assegura que el formulari ocupa tota l'amplada disponible */
  max-width: 800px;        /* Limita l'amplada màxima del formulari */
  margin: 0 auto;          /* Centra el formulari horitzontalment */
}

.register-form .input-field {
  all: unset;              /* Elimina tots els estils per defecte del navegador */
  display: block;          /* Display block per assegurar-se que ocupa tota l'amplada disponible */
  width: 100%;             /* Amplada completa del contenidor pare */
  padding: 8px 15px;       /* Padding personalitzat */
  border-radius: 4px;      /* Radius personalitzat */
  border: 2px solid #209385; /* Border personalitzat */
  background-color: white; /* Color de fons personalitzat */
  font-size: 16px;         /* Mida de font personalitzada */
  box-sizing: border-box;  /* Inclou padding i border a l'amplada total de l'element */
  margin-bottom: 10px;     /* Espai entre els camps */
  text-align: left;        /* Text alineat a l'esquerra */
}

.register-form .input-field:focus {
  border-color: #209385;   /* Color del border quan l'element està enfocat */
  outline: none;           /* Elimina l'outline per defecte */
}

.wide-input {
  width: 100%; /* Ajusta l'amplada de les textareas */
  text-align: left; /* Assegura que el text estigui alineat a l'esquerra */
}

.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.error-text {
  color: red;
  font-size: 12px;
  margin: 5px 0;
}  

input[type="checkbox"] {
  margin-right: 10px;
}

label {
  cursor: text;
  font-size: 16px;
  display: block; /* Asegura que els labels ocupin tota l'amplada disponible i es posin sobre els inputs */
  margin-bottom: 5px; /* Espai entre el label i l'input */
  margin-top: 5px; /* Espai entre el label i l'input */
}

/* Modal */
.ReactModal__Overlay {
  z-index: 9999 !important; /* Assegura que el modal es mostri per sobre de tots els altres elements */
}

.horizontal-labels {
  display: inline-block; /* Fa que les etiquetes es mostrin una al costat de l'altra */
  margin-right: 15px; /* Afegeix espai entre les etiquetes */
  vertical-align: middle; /* Assegura que les checkboxes i els labels estiguin alineats */
}

.horizontal-labels input[type="checkbox"] {
  margin-right: 5px; /* Espai entre la checkbox i el text */
}