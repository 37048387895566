/* openResearchHub.css */

.container {
    padding: 16px;
    align-items: center;
  }
  
  .projects-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
    align-items: start; /* Mantenir la mida inicial de les targetes */
}

.research-card {
    border: 2px solid #209385;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px; /* Alçada mínima fixa per evitar que es redueixi */
}

/* Quan la targeta està expandida, mantenir l'alçada sense afectar les altres */
.research-card.expanded {
    min-height: auto; /* Permet que creixi, però sense afectar les altres */
    background-color: #F4F9F9;
}
  
  .research-card h2 {
    color: #209385;
  }

  .research-card h3 {
    color: #209385;
    text-align: center;
    font-weight: normal;
    font-size: larger;
}
  
  .expand-toggle {
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    align-self: center;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .expand-toggle::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #209385;
    transition: transform 0.3s ease-in-out;
  }
  
  .expanded .expand-toggle::after {
    transform: rotate(180deg);
  }
  
  .expanded {
    background-color: #F4F9F9;

  }

  .button-participate {
    all: unset; /* Remove all inherited and browser-default styles */
    display: block; /* Ensure it behaves as a block element */
    padding: 9px 15px; /* Custom padding */
    border-radius: 4px; /* Custom border radius */
    border: 2px solid #209385; /* Custom border */
    background-color: white; /* Custom background color */
    font-size: 16px; /* Custom font size */
    cursor: pointer; /* Pointer cursor on hover */
    text-align: center; /* Center text inside button */
    white-space: nowrap; /* Prevent text wrapping */
    box-sizing: border-box; /* Ensure padding and border are included in dimensions */
    color: #000000; /* Custom text color */
    width: auto; /* Allow the button to resize naturally */
    margin: 10px auto; /* Center the button horizontally */
    margin-top: 16px;
}