/* Media container styles */
.audio-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* General media controls styles */
.media-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.media-controls > div {
  flex: 1;
  text-align: center;
  margin-bottom: 20px; /* Afegeix un marge inferior per espaiar els elements verticalment */
}