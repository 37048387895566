/* Responsive styles */

/* Reducció de la mida de lletra per a pantalles petites*/
@media screen and (max-width: 600px) {
  body {
    font-size: 16px; 
  }

  /* Redueix els marges per a un millor aprofitament de l'espai en pantalles petites*/
  .main-content {
    margin: 10px;
  }

  /* Reducció del padding per a header i footer*/
  header, footer {
    padding: 5px 0; 
  }

  /* Elements de navegació passen a ser en bloc i centrats*/
  nav ul li {
    display: block;
    margin-right: 0;
    text-align: center; 
  }

  /*Contingut del footer s'expandeix a l'amplada completa*/
  .footer-content {
    width: 100%; 
  }
}
  