/* ===== General Form ===== */
.custom-resource-form {
    width: 100%; /* Amplada completa */
    max-width: 1200px; /* Amplada màxima per a pantalles grans */
    margin: 0 auto; /* Centrat a la pantalla */
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}

/* ===== Headers ===== */
.custom-resource-form h2 {
    font-size: 1.8em;
    color: #209385;
    margin-bottom: 20px;
    text-align: center;
}

.custom-resource-form h3 {
    font-size: 1em;
    color: #209385;
    margin-bottom: 20px;
    text-align: left;
}

/* ===== Checkbox Label ===== */
.custom-resource-checkbox-label-inline {
    display: flex;
    align-items: center;
    gap: 3px; /* Separació entre checkbox i etiqueta */
    font-size: 16px;
    margin-bottom: 15px;
}

/* ===== Radio Buttons ===== */
.custom-resource-radio-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Més separació horitzontal entre els radio buttons */
    margin-bottom: 20px; /* Més separació vertical amb altres elements */
}

.custom-resource-radio-buttons label {
    display: flex;
    align-items: center;
    gap: 12px; /* Més separació horitzontal entre el cercle i el text */
}

/* ===== VAS Scale ===== */
.custom-resource-rating {
    display: flex;
    flex-direction: column;
    gap: 25px; /* Més separació vertical entre el slider i altres elements */
    margin-bottom: 25px; /* Separació extra amb elements inferiors */
}

.custom-resource-slider {
    flex: 1;
    appearance: none;
    height: 8px; /* Augmenta l’alçada del track del slider */
    background-color: #ddd;
    border-radius: 4px;
    outline: none;
}

.custom-resource-slider::-webkit-slider-thumb {
    appearance: none;
    width: 12px; /* Amplada augmentada */
    height: 12px; /* Alçada augmentada */
    background: #209385;
    border-radius: 50%; /* Botó arrodonit */
    cursor: pointer;
}

.custom-resource-slider::-moz-range-thumb {
    width: 12px; /* Amplada augmentada */
    height: 12px; /* Alçada augmentada */
    background: #209385;
    border-radius: 50%; /* Botó arrodonit */
    cursor: pointer;
}

/* ===== Textarea ===== */
.custom-resource-textarea {
    all: unset;
    width: 100%; /* Amplada completa */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #209385;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 50px;
    resize: vertical; /* Permet redimensionar */
}

/* ===== Responsive Design ===== */
@media (max-width: 768px) {
    .custom-resource-form {
        padding: 15px;
    }

    .custom-resource-radio-buttons {
        flex-direction: column; /* Radio buttons en columna */
    }
}