:root {
    --border-radius-500: 8px;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    min-height: 100vh;
  }
  
  .layout__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .card {
    flex: 1; /* Equal flex grow factor */
    display: flex; /* Set display to flex */
    flex-direction: column; /* Stack child elements vertically */
    justify-content: space-between; /* Space between child elements */
    padding: 20px;
    margin: 10px;
    border-radius: var(--border-radius-500);
    box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
    min-height: 200px; /* Set a minimum height for the card */
  }
  
  .spacer {
    flex-grow: 1;
  }
  
  .remove-sort-button {
    position: absolute;
    bottom: 10px; /* Position at the bottom of the card */
    left: 10px; /* Adjust as needed */
  }
  
  .first-container {
    flex: 1; /* Equal flex grow factor */
    padding: 20px;
    margin: 10px;
    border-radius: var(--border-radius-500);
    box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
  }
  
  .header {
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  
  .sorts-container {
    flex: 1; /* Equal flex grow factor */
    padding: 20px;
    margin: 10px;
    border-radius: var(--border-radius-500);
    box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .sort-container {
    min-height: 50px; /* or a suitable value */
    margin-bottom: 10px; /* spacing between containers */
  }
  
  .items-container {
    text-align: center;
  }
  
  .item-container {
    padding: 5px;
    border-bottom: 1px solid grey;
  }
  
  .droppable-area {
    min-height: 100px; /* Increase the minimum height */
    padding: 20px; /* Add padding for a larger droppable area */
  }
  
  .droppable-area:hover {
    background-color: rgb(245, 245, 245); /* Visual feedback on hover */
  }

  .draggable-item {
    padding: 10px;
    margin-bottom: 8px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }