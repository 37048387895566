/* Hero */
.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Evita que la imatge es repeteixi */
  color: #061d1a;
  padding: 15px; /* Redueix el padding global */
  margin-bottom: 15px; /* Redueix el marge inferior */
}

.hero-content {
  background-color: #d2e9e6;
  padding: 15px; /* Manté el padding ajustat per a un espai equilibrat */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra els elements horitzontalment */
  border-radius: 10px;
  max-width: 80%; /* Limita l'amplada per evitar que el contingut es vegi massa estirat */
  margin: 0 auto; /* Centra el contenidor horizontalment */
}

.hero h1 {
  font-size: 2.4em; /* Ajusta la mida de la font */
  margin-bottom: 5px; /* Redueix l'espai inferior entre l'h1 i l'h2 */
  text-align: center; /* Centra el text */
}

.hero h2 {
  font-size: 1.6em; /* Ajusta la mida de la font */
  margin-top: 0; /* Elimina l'espai superior entre l'h1 i l'h2 */
  margin-bottom: 10px; /* Manté un espai adequat a sota de l'h2 */
  text-align: center; /* Centra el text */
}

.hero h3 {
  font-size: 1.2em; /* Ajusta la mida de la font */
  margin-top: 10px; /* Elimina l'espai superior entre l'h1 i l'h3 */
  margin-bottom: 20px; /* Manté un espai adequat a sota de l'h3 */
  text-align: center; /* Centra el text */
}

.hero p {
  font-size: 1em; /* Ajusta la mida de la font */
  margin-bottom: 20px; /* Redueix l'espai inferior */
  text-align: center; /* Centra el text */
}

.hero-buttons {
  display: flex;
  justify-content: center; /* Centra els botons horizontalment */
  width: 100%;
  margin-top: 10px; /* Redueix l'espai superior per als botons */
}

.hero-button {
  margin-left: 8px;
  display: inline-block;
  background-color: white;
  border: 2px solid #209385;
  color: #061d1a;
  text-decoration: none;
  font-size: 1em;
  border-radius: 5px;
  padding: 20px 30px;
}

.hero-button:hover {
  background-color: #209385; /* Canvia el color de fons a verd */
  color: white; /* Canvia el color del text a blanc */
  transform: scale(1.05); /* Légger augment en mida */
  cursor: pointer; /* Canvia el cursor a una mà quan es passa per sobre */
}