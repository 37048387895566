/* Progress bar */

.progress-container {
    width: 100%;
    background-color: #eee;
    border-radius: 5px;
  }
  
  .progress-bar {
    height: 20px;
    background-color: #4CAF50;
    border-radius: 5px;
    text-align: center;
    line-height: 20px; /* Same as height */
    color: white;
  }