/* long.css */

.long-text-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.long-section {
    margin-bottom: 40px;
}

.long-section h1 {
    font-size: 26px;
    color: #209385;
    border-bottom: 2px solid #209385;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.long-section h2 {
    font-size: 22px;
    color: #209385;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.long-section h3 {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}

.long-section h4 {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}

.long-section p {
    margin-bottom: 10px;
}