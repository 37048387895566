/* Navigation styles */
header {
  background-color: #f0f0f0;
  padding: 10px 0;
  text-align: center;
}

nav ul {
  list-style: none; /* Elimina les marques de llista */
  padding: 0; /* Elimina el padding predeterminat */
}

nav ul li {
  display: inline-block;
  position: relative;
  padding: 8px 16px;
  margin: 5px; /* Espai entre elements de menú */
  border-radius: 4px; /* Racons arrodonits */
  background-color: #ffffff; /* Fons blanc per coherència amb els botons */
  border: 2px solid #209385; /* Borda consistent amb els botons */
  color: black;
  cursor: pointer; /* Cursor de punter per indicar interactivitat */
  white-space: nowrap;
}

nav ul li:hover {
  background-color: #209385; /* Color de fons en passar el cursor */
  color: #ffffff;
}

nav ul li ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #f0f0f0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px; /* Racons arrodonits */
}

nav ul li:hover > ul {
  display: block; /* Mostra el submenú en passar el cursor */
}

nav ul li ul li {
  display: block;
  padding: 8px 16px;
  text-align: left; /* Alineació del text a l'esquerra per consistència */
  border-bottom: 1px solid #ccc; /* Borda per separar elements del submenú */
}

nav ul li ul li:last-child {
  border-bottom: none; /* No aplica borda al darrer element del submenú */
}

nav ul li ul li a {
  color: black;
  text-decoration: none;
  display: block; /* Fa que tot l'àrea sigui clicable */
}

nav ul li ul li a:hover {
  background-color: #209385; /* Fons en passar el cursor */
  color: #ffffff;
}

/* Responsive menu button */
header nav button {
  display: none; /* Hidden by default, can be displayed in responsive design */
}
  