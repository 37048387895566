/* Styles for Anchors, Marks, LineWithMarks, LineLabel */

.line-label {
    display: block; 
    width: fit-content;
  }
  
  .line {
    position: relative; /* Keep this for positioning the marks */
    height: 2px;
    background-color: black;
    width: 100%; 
    margin-top: 35px;
    margin-bottom: 35px;
  }
  
  .mark {
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: rgb(150, 150, 150);
    cursor: pointer;
    top: -10px;
  }
  
  .mark.selected {
    background-color: red;
    width: 2px;
    height: 40px;
    top: -30px;
  }
  
  .blue-mark {
    position: absolute;
    width: 2px;
    height: 40px;
    background-color: blue;
    cursor: pointer;
    top: -10px;
  }
  
  .selected-blue {
    background-color: red; /* Change this to the desired color for selected blue marks */
  }