/* Slider Styles */

.slider-container {
  background: #fff; 
}

.inputContainer {
  margin-bottom: 10px; /* Afegeix marge inferior per separar els sliders */
}

input[type="range"] {
  width: 100%;
  background: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  background: #000; /* Pots canviar el color de la pista aquí si és necessari */
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  background: #000; /* Pots canviar el color de la pista aquí si és necessari */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #209385 !important;
  cursor: pointer;
  border: none;
  position: relative;
  top: -7px;
  z-index: 1;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #209385 !important;
  cursor: pointer;
  position: relative;
  top: -7px;
  z-index: 1;
}